import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
    ads: null,
    advert: null,
    adsDialog: false,
    bidsInfo: [],
    advertInfo: {},
    shipments: "",
    myRates: "",
    completedAdverts: "",
    bidModal: false,
    sendBidResponse: {
        ValidationErrors: [],
    },
    cancelResponse: {
        ValidationErrors: [],
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
