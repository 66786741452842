export default {
    advertsDetail: state => state.ads,
    singleAdvertDetail: state => state.advert,
    adsDialog: state => state.adsDialog,
    bidsInfo: state => state.bidsInfo,
    advertInfo: state => state.advertInfo,
    shipments: state => state.shipments,
    myRates: state => state.myRates,
    completedAdverts: state => state.completedAdverts,
    ratingDialog: state => state.ratingDialog,
    bidModal: state => state.bidModal,
    sendBidResponse: state => state.sendBidResponse,
    cancelResponse: state => state.cancelResponse,
}
