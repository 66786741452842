<template>
	<div class="content-body" :class="[$store.state.User.toggleLoginDialog ? 'm-0 pt-0' : '']">
		<div class="container-fluid">
			<!-- row -->
			<div class="row">
				<div class="col-xl-12 col-xxl-12">
					<div class="card">
						<div class="card-header d-flex justify-content-center">
							<a class="logo" href="javascript:;" @click="() => this.$router.push({ path: '/' })"
								><img src="../../assets/images/whitelogo.svg" alt=""
							/></a>
						</div>
						<div class="card-body">
							<INStepper :activeRegister="activeRegister" />

							<div class="row" v-if="activeRegister === 1">
								<div class="col-lg-12 my-4 text-center">
									<h2 class="text-white">Lütfen kullanım amacınızı seçiniz</h2>
								</div>
								<div class="col-lg-6 my-3" style="min-height: 375px">
									<div class="mb-3 text-center flex-column">
										<a
											id="supplier"
											href="javascript:;"
											class="d-flex white-bg flex-column justify-content-center align-items-center"
											@click="
												() => {
													isCarrier = false
												}
											"
										>
											<img
												src="../../assets/images/tedarikci.svg"
												alt=""
												style="max-width: 300px; width: 100%; height: 300px"
											/>
										</a>
										<span class="mb-3 text-white">Nakliye için taşıta ihtiyacım var.</span>
									</div>
								</div>
								<div class="col-lg-6 my-3" style="min-height: 375px">
									<div class="mb-3 text-center">
										<a
											id="carrier"
											href="javascript:;"
											class="d-flex white-bg flex-column justify-content-center align-items-center"
											@click="
												() => {
													isCarrier = true
												}
											"
										>
											<img
												src="../../assets/images/nakliyeci.svg"
												alt=""
												style="max-width: 300px; width: 100%; height: 300px"
											/>
										</a>
										<span class="mb-3 text-white">Nakliye yapabilirim.</span>
									</div>
								</div>
							</div>

							<div class="row" v-if="activeRegister === 2">
								<div class="mb-2 col-lg-6">
									<div class="mb-3">
										<label class="text-label form-label">İsim</label>
										<el-input
											type="text"
											name="firstName"
											class="form-control"
											:class="{
												'input-validation-error': signUpUserResponse.ValidationErrors.some((x) => x.Key == 'FirstName'),
											}"
											placeholder="..."
											required
											v-model="Form.FirstName"
										/>
										<span
											class="validation-error"
											v-if="signUpUserResponse.ValidationErrors.some((x) => x.Key == 'FirstName')"
											>{{ signUpUserResponse.ValidationErrors.filter((x) => x.Key == 'FirstName')[0].Value }}</span
										>
									</div>
								</div>
								<div class="col-lg-6 mb-2">
									<div class="mb-3">
										<label class="text-label form-label">Soyisim</label>
										<el-input
											type="text"
											name="lastName"
											class="form-control"
											:class="{
												'input-validation-error': signUpUserResponse.ValidationErrors.some((x) => x.Key == 'LastName'),
											}"
											placeholder="..."
											required
											v-model="Form.LastName"
										/>
										<span
											class="validation-error"
											v-if="signUpUserResponse.ValidationErrors.some((x) => x.Key == 'LastName')"
											>{{ signUpUserResponse.ValidationErrors.filter((x) => x.Key == 'LastName')[0].Value }}</span
										>
									</div>
								</div>
								<div class="col-lg-6 mb-2">
									<div class="mb-3">
										<label class="text-label form-label">Telefon</label>
										<el-input
											class="w-100 form-control py-0"
											:class="{
												'input-validation-error': signUpUserResponse.ValidationErrors.some(
													(x) => x.Key == 'PhoneNumber'
												),
											}"
											v-mask="['+90 (###) ### ## ##']"
											placeholder="+90 (xxx) xxx xx xx"
											v-model="Form.PhoneNumber"
										></el-input>
										<span
											class="validation-error"
											v-if="signUpUserResponse.ValidationErrors.some((x) => x.Key == 'PhoneNumber')"
											>{{ signUpUserResponse.ValidationErrors.filter((x) => x.Key == 'PhoneNumber')[0].Value }}</span
										>
									</div>
								</div>

								<div class="col-lg-6 mb-2">
									<div class="mb-3">
										<label class="text-label form-label">E-posta</label>
										<el-input
											type="email"
											class="form-control"
											:class="{
												'input-validation-error': signUpUserResponse.ValidationErrors.some((x) => x.Key == 'Email'),
											}"
											id="el-inputGroupPrepend2"
											aria-describedby="el-inputGroupPrepend2"
											placeholder="birisi@ornek.com"
											required
											v-model="Form.Email"
										/>
										<span
											class="validation-error"
											v-if="signUpUserResponse.ValidationErrors.some((x) => x.Key == 'Email')"
											>{{ signUpUserResponse.ValidationErrors.filter((x) => x.Key == 'Email')[0].Value }}</span
										>
									</div>
								</div>

								<!-- <div v-if="isCarrier" class="col-lg-6 mb-2">
                  <div class="mb-3">
                    <label class="text-label form-label d-block"
                      >Doğum Tarihi</label
                    >
                    <el-date-picker
                      v-model="Form.BirthDate"
                      type="date"
                      placeholder="Doğum Tarihinizi Seçiniz"
                      class="form-control w-100"
                      :class="{
                        'input-validation-error':
                          signUpUserResponse.ValidationErrors.some(
                            (x) => x.Key == 'BirthDate'
                          ),
                      }"
                    >
                    </el-date-picker>
                    <span
                      class="validation-error"
                      v-if="
                        signUpUserResponse.ValidationErrors.some(
                          (x) => x.Key == 'BirthDate'
                        )
                      "
                      >{{
                        signUpUserResponse.ValidationErrors.filter(
                          (x) => x.Key == "BirthDate"
                        )[0].Value
                      }}</span
                    >
                  </div>
                </div> -->

								<!-- <div class="col-lg-6 mb-2">
                  <div class="mb-3">
                    <label class="text-label form-label">İl</label>
                    <el-select
                      v-model="Form.CityId"
                      placeholder="..."
                      class="nice-select default-select form-control wide mb-3"
                      :class="{
                        'input-validation-error':
                          signUpUserResponse.ValidationErrors.some(
                            (x) => x.Key == 'CityId'
                          ),
                      }"
                    >
                      <el-option
                        v-for="item in cities"
                        :key="item.ID"
                        :label="item.Title"
                        :value="item.ID"
                      >
                      </el-option>
                    </el-select>
                    <span
                      class="validation-error"
                      v-if="
                        signUpUserResponse.ValidationErrors.some(
                          (x) => x.Key == 'CityId'
                        )
                      "
                      >{{
                        signUpUserResponse.ValidationErrors.filter(
                          (x) => x.Key == "CityId"
                        )[0].Value
                      }}</span
                    >
                  </div>
                </div>

                <div class="col-lg-6 mb-3">
                  <div class="mb-3">
                    <label class="text-label form-label">İlçe</label>
                    <el-select
                      v-model="Form.DistrictId"
                      placeholder="..."
                      class="nice-select default-select form-control wide mb-3"
                      :class="{
                        'input-validation-error':
                          signUpUserResponse.ValidationErrors.some(
                            (x) => x.Key == 'DistrictId'
                          ),
                      }"
                    >
                      <el-option
                        v-for="item in districts"
                        :key="item.ID"
                        :label="item.Title"
                        :value="item.ID"
                      >
                      </el-option>
                    </el-select>
                    <span
                      class="validation-error"
                      v-if="
                        signUpUserResponse.ValidationErrors.some(
                          (x) => x.Key == 'DistrictId'
                        )
                      "
                      >{{
                        signUpUserResponse.ValidationErrors.filter(
                          (x) => x.Key == "DistrictId"
                        )[0].Value
                      }}</span
                    >
                  </div>
                </div> -->

								<div class="mb-3 col-md-6">
									<label class="form-label">Şifre</label>
									<div
										class="form-control el-input"
										:class="{
											'input-validation-error': signUpUserResponse.ValidationErrors.some((x) => x.Key == 'Password'),
										}"
									>
										<input
											id="password"
											type="password"
											class="el-input__inner"
											placeholder="******"
											v-model="Form.Password"
											@keypress.enter="next"
										/>
										<i @click="showPassword" class="bi bi-eye-slash password-icon" id="togglePassword"></i>
									</div>
									<span
										class="validation-error"
										v-if="signUpUserResponse.ValidationErrors.some((x) => x.Key == 'Password')"
										>{{ signUpUserResponse.ValidationErrors.filter((x) => x.Key == 'Password')[0].Value }}</span
									>
								</div>

								<div class="mb-3 col-md-6">
									<label class="form-label">Şifre Tekrar</label>
									<div
										class="form-control el-input"
										:class="{
											'input-validation-error': signUpUserResponse.ValidationErrors.some((x) => x.Key == 'Password'),
										}"
									>
										<input
											id="passwordRetry"
											type="password"
											class="el-input__inner"
											placeholder="******"
											v-model="Form.PasswordRetry"
											@keypress.enter="next"
										/>
										<i @click="showPasswordRetry" class="bi bi-eye-slash password-icon" id="togglePasswordRetry"></i>
									</div>
									<span
										class="validation-error"
										v-if="signUpUserResponse.ValidationErrors.some((x) => x.Key == 'PasswordRetry')"
										>{{ signUpUserResponse.ValidationErrors.filter((x) => x.Key == 'PasswordRetry')[0].Value }}</span
									>
								</div>
								<div class="mb-3 mt-3 col-md-12 d-flex justify-content-between">
									<div>Davet Kodum Var</div>
									<div
										class="d-flex justify-content-between align-items-center notifications border-radius py-2 px-3 mb-4 switch-register"
									>
										<el-switch
											v-model="hasInvitationCode"
											class="float-right"
											active-color="#E9501C"
											inactive-color="#4D668A"
										>
										</el-switch>
									</div>
								</div>
								<div class="col-lg-12 mb-2">
									<div class="mb-3">
										<el-input
											v-if="hasInvitationCode"
											type="text"
											v-model="Form.InvitationCode"
											class="form-control mb-3"
											:class="{
												'input-validation-error': signUpUserResponse.ValidationErrors.some(
													(x) => x.Key == 'InvitationCode'
												),
											}"
											placeholder="Davet Kodunuzu Giriniz"
											required
										/>
										<span
											class="validation-error"
											v-if="signUpUserResponse.ValidationErrors.some((x) => x.Key == 'InvitationCode')"
											>{{ signUpUserResponse.ValidationErrors.filter((x) => x.Key == 'InvitationCode')[0].Value }}</span
										>
									</div>
								</div>
								<div class="d-flex">
									<input
										type="checkbox"
										@click="handleCheckbox"
										v-model="registerPolicies.kvkk"
										class="form-check-input"
										style="width: 1.25rem !important; height: 1.25rem !important"
									/>
									<label class="form-check-label">
										<span @click="kvkkDialog = true" class="policy-text">KVKK metnini</span>
										okudum, anladım kabul ediyorum.</label
									>
								</div>
								<div class="d-flex">
									<input
										type="checkbox"
										@click="handleCheckbox"
										v-model="registerPolicies.userAgreement"
										class="form-check-input"
										style="width: 1.25rem !important; height: 1.25rem !important"
									/>
									<label class="form-check-label"
										><span @click="userAgreementDialog = true" class="policy-text"
											>Nakliye Kullan Kullanıcı sözleşmesini</span
										>
										okudum, anladım kabul ediyorum.</label
									>
								</div>
								<!-- <div class="d-flex">
                  <input
                    type="checkbox"
                    @click="handleCheckbox"
                    v-model="registerPolicies.confidentialityAgreement"
                    class="form-check-input"
                    style="
                      width: 1.25rem !important;
                      height: 1.25rem !important;
                    "
                  />
                  <label class="form-check-label"
                    ><span
                      @click="confidentialityAgreementDialog = true"
                      class="policy-text"
                      >Gizlilik Sözleşmesini</span
                    >
                    okudum, anladım kabul ediyorum.</label
                  >
                </div>
                <div class="d-flex">
                  <input
                    type="checkbox"
                    @click="handleCheckbox"
                    v-model="registerPolicies.location"
                    class="form-check-input"
                    style="
                      width: 1.25rem !important;
                      height: 1.25rem !important;
                    "
                  />
                  <label class="form-check-label"
                    ><span @click="locationDialog = true" class="policy-text"
                      >Lokasyon Verilerinin İşlenmesine İlişkin Aydınlatma
                      Metnini</span
                    >
                    okudum, anladım kabul ediyorum.</label
                  >
                </div> -->
							</div>

							<div class="row" v-if="activeRegister === 3">
								<div class="col-md-12 text-center mb-4">
									<label for="logo">Logo </label>
									<el-upload
										class="avatar-uploader"
										name="logo"
										:action="'https://api.nakliyekullan.com/' + 'Account/UploadImage'"
										:headers="{
											Authorization: 'Bearer ' + getToken(),
										}"
										:show-file-list="false"
										:on-success="handleAvatarSuccess"
									>
										<img v-if="BillForm.CompanyLogo" :src="BillForm.CompanyLogo" class="avatar" />
										<i
											v-else
											class="el-icon-plus avatar-uploader-icon"
											:class="{
												'input-validation-error': companyResponse.ValidationErrors.some((x) => x.Key == 'CompanyLogo'),
											}"
										></i>
									</el-upload>
									<span
										class="validation-error"
										v-if="companyResponse.ValidationErrors.some((x) => x.Key == 'CompanyLogo')"
										>{{ companyResponse.ValidationErrors.filter((x) => x.Key == 'CompanyLogo')[0].Value }}</span
									>
								</div>
								<div class="col-lg-6 mb-2">
									<div class="mb-3">
										<label class="text-label form-label">Firma Adı</label>
										<el-input
											type="text"
											name="firstName"
											class="form-control"
											:class="{
												'input-validation-error': companyResponse.ValidationErrors.some((x) => x.Key == 'Title'),
											}"
											autocomplete="false"
											placeholder="Firma Adı Giriniz"
											required
											v-model="BillForm.Title"
										/>
										<span
											class="validation-error"
											v-if="companyResponse.ValidationErrors.some((x) => x.Key == 'Title')"
											>{{ companyResponse.ValidationErrors.filter((x) => x.Key == 'Title')[0].Value }}</span
										>
									</div>
								</div>

								<div class="col-lg-6 mb-2">
									<div class="mb-3">
										<label class="text-label form-label">E-posta</label>
										<el-input
											type="email"
											class="form-control"
											:class="{
												'input-validation-error': companyResponse.ValidationErrors.some((x) => x.Key == 'Email'),
											}"
											id="el-inputGroupPrepend2"
											autocomplete="false"
											aria-describedby="el-inputGroupPrepend2"
											placeholder="birisi@ornek.com"
											required
											v-model="BillForm.Email"
										/>
										<span
											class="validation-error"
											v-if="companyResponse.ValidationErrors.some((x) => x.Key == 'Email')"
											>{{ companyResponse.ValidationErrors.filter((x) => x.Key == 'Email')[0].Value }}</span
										>
									</div>
								</div>

								<div class="col-lg-6 mb-2">
									<div class="mb-3">
										<label class="text-label form-label">Telefon</label>
										<el-input
											class="w-100 bg-light form-control py-0"
											:class="{
												'input-validation-error': companyResponse.ValidationErrors.some((x) => x.Key == 'PhoneNumber'),
											}"
											v-mask="['+90 (###) ### ## ##']"
											placeholder="+90 (xxx) xxx xx xx"
											v-model="BillForm.PhoneNumber"
										></el-input>
										<span
											class="validation-error"
											v-if="companyResponse.ValidationErrors.some((x) => x.Key == 'PhoneNumber')"
											>{{ companyResponse.ValidationErrors.filter((x) => x.Key == 'PhoneNumber')[0].Value }}</span
										>
									</div>
								</div>
								<!-- <div class="col-lg-6 mb-2">
                  <label class="text-label form-label">İl</label>
                  <el-select
                    v-model="BillForm.CityId"
                    placeholder="..."
                    class="nice-select default-select form-control wide mb-3"
                    :class="{
                      'input-validation-error':
                        companyResponse.ValidationErrors.some(
                          (x) => x.Key == 'CityId'
                        ),
                    }"
                  >
                    <el-option
                      v-for="item in cities"
                      :key="item.ID"
                      :label="item.Title"
                      :value="item.ID"
                    >
                    </el-option>
                  </el-select>
                  <span
                    class="validation-error"
                    v-if="
                      companyResponse.ValidationErrors.some(
                        (x) => x.Key == 'CityId'
                      )
                    "
                    >{{
                      companyResponse.ValidationErrors.filter(
                        (x) => x.Key == "CityId"
                      )[0].Value
                    }}</span
                  >
                </div>

                <div class="col-lg-6 mb-3">
                  <label class="text-label form-label">İlçe</label>
                  <el-select
                    v-model="BillForm.DistrictId"
                    placeholder="..."
                    class="nice-select default-select form-control wide mb-3"
                    :class="{
                      'input-validation-error':
                        companyResponse.ValidationErrors.some(
                          (x) => x.Key == 'DistrictId'
                        ),
                    }"
                  >
                    <el-option
                      v-for="item in districts"
                      :key="item.ID"
                      :label="item.Title"
                      :value="item.ID"
                    >
                    </el-option>
                  </el-select>
                  <span
                    class="validation-error"
                    v-if="
                      companyResponse.ValidationErrors.some(
                        (x) => x.Key == 'DistrictId'
                      )
                    "
                    >{{
                      companyResponse.ValidationErrors.filter(
                        (x) => x.Key == "DistrictId"
                      )[0].Value
                    }}</span
                  >
                </div> -->

								<div class="col-lg-6 mb-2">
									<div class="mb-3">
										<label class="text-label form-label">Vergi Numarası</label>
										<el-input
											type="text"
											name="taxNo"
											autocomplete="false"
											class="form-control"
											:class="{
												'input-validation-error': companyResponse.ValidationErrors.some((x) => x.Key == 'TaxNo'),
											}"
											placeholder="Vergi Numarası Giriniz"
											maxlength="10"
											required
											v-model="BillForm.TaxNo"
										/>
										<span
											class="validation-error"
											v-if="companyResponse.ValidationErrors.some((x) => x.Key == 'TaxNo')"
											>{{ companyResponse.ValidationErrors.filter((x) => x.Key == 'TaxNo')[0].Value }}</span
										>
									</div>
								</div>

								<div class="col-lg-6 mb-2">
									<div class="mb-3">
										<label class="text-label form-label">Vergi Dairesi</label>
										<el-input
											type="text"
											name="taxOffice"
											autocomplete="false"
											class="form-control"
											:class="{
												'input-validation-error': companyResponse.ValidationErrors.some((x) => x.Key == 'TaxOffice'),
											}"
											placeholder="Vergi Dairesi Giriniz"
											required
											v-model="BillForm.TaxOffice"
										/>
										<span
											class="validation-error"
											v-if="companyResponse.ValidationErrors.some((x) => x.Key == 'TaxOffice')"
											>{{ companyResponse.ValidationErrors.filter((x) => x.Key == 'TaxOffice')[0].Value }}</span
										>
									</div>
								</div>

								<div class="mb-3 col-md-6">
									<label class="form-label">Adres</label>
									<textarea
										class="form-control"
										:class="{
											'input-validation-error': companyResponse.ValidationErrors.some((x) => x.Key == 'Address'),
										}"
										placeholder="Lütfen Bir Adres Giriniz"
										v-model="BillForm.Address"
									></textarea>
									<span
										class="validation-error"
										v-if="companyResponse.ValidationErrors.some((x) => x.Key == 'Address')"
										>{{ companyResponse.ValidationErrors.filter((x) => x.Key == 'Address')[0].Value }}</span
									>
								</div>
							</div>

							<el-button
								v-if="activeRegister === 1"
								class="bg-secondary py-3"
								style="margin-top: 12px; float: left"
								@click="goBackToApp"
								>Geri Dön</el-button
							>
							<el-button
								v-if="activeRegister === 2"
								class="bg-secondary py-3"
								style="margin-top: 12px; float: left"
								@click="back"
								>Geri Dön</el-button
							>
							<el-button
								class="bg-primary py-3"
								style="margin-top: 12px; float: right"
								@click="next"
								:disabled="activeRegister === 1 && isCarrier == null"
								>Sonraki Adım</el-button
							>
							<el-button
								v-if="activeRegister === 3"
								class="bg-secondary py-3"
								style="margin-top: 12px; float: left"
								@click="navigateToHomePage"
								>Bu Adımı Geç</el-button
							>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog
			id="kvkk-policy"
			:title="kvkkInfo.Title"
			:close-on-click-modal="false"
			:visible.sync="kvkkDialog"
			@opened="handleDialogOpen"
		>
			<div class="d-flex flex-column policy">
				<div id="kvkk-policy-inside" v-html="kvkkInfo.Content"></div>
				<el-button class="cookie-button m-0" @click="handleAcceptKvkk"> Kabul et ve kapat</el-button>
			</div>
		</el-dialog>
		<el-dialog
			id="user-policy"
			:title="userAgreementInfo.Title"
			:close-on-click-modal="false"
			:visible.sync="userAgreementDialog"
			@opened="handleDialogOpen"
		>
			<div class="d-flex flex-column policy">
				<div id="user-policy-inside" v-html="userAgreementInfo.Content"></div>
				<el-button class="cookie-button m-0" @click="handleAcceptUserAgreement"> Kabul et ve kapat</el-button>
			</div>
		</el-dialog>
		<el-dialog
			:title="confidentialityAgreementInfo.Title"
			:close-on-click-modal="false"
			:visible.sync="confidentialityAgreementDialog"
			@opened="handleDialogOpen"
		>
			<div class="d-flex flex-column">
				<div v-html="confidentialityAgreementInfo.Content"></div>
				<el-button class="cookie-button m-0" @click="handleAcceptConfidentialityAgreement">
					Kabul et ve kapat</el-button
				>
			</div>
		</el-dialog>
		<el-dialog
			:title="locationInfo.Title"
			:close-on-click-modal="false"
			:visible.sync="locationDialog"
			@opened="handleDialogOpen"
		>
			<div class="d-flex flex-column">
				<div v-html="locationInfo.Content"></div>
				<el-button class="cookie-button m-0" @click="handleAcceptLocation"> Kabul et ve kapat</el-button>
			</div>
		</el-dialog>
		<button
			:class="{ 'd-none': !kvkkDialog && !userAgreementDialog }"
			class="scroltop fa fa-arrow-down degiv-c-white degiv-bg-orange policy-scroll"
			style=""
		></button>
	</div>
</template>

<script>
	import $ from 'jquery'
	import META_DATA from '../../constants/metaData'
	import INStepper from '../../components/INStepper/INStepper.vue'
	import POLICIES from '../../constants/policies'

	export default {
		components: { INStepper },
		metaInfo() {
			return {
				title: META_DATA.REGISTER.Title,
			}
		},
		name: 'Register',
		data() {
			return {
				imageUrl: '',
				isCarrier: null,
				cities: null,
				districts: null,
				Form: {
					FirstName: null,
					LastName: null,
					PhoneNumber: null,
					Email: null,
					Password: null,
					PasswordRetry: null,
					CityId: null,
					DistrictId: null,
					TCKimlikNo: null,
					InvitationCode: null,
				},
				BillForm: {
					CompanyLogo: null,
					Title: null,
					Email: null,
					PhoneNumber: null,
					CityId: null,
					DistrictId: null,
					TaxNo: null,
					TaxOffice: null,
					Address: null,
				},
				locationDialog: false,
				kvkkDialog: false,
				confidentialityAgreementDialog: false,
				userAgreementDialog: false,
				registerPolicies: {
					kvkk: false,
					userAgreement: false,
				},
				hasInvitationCode: false,
			}
		},
		async beforeMount() {
			await this.$store.dispatch('getCities')
			this.cities = this.$store.getters['cityState']
		},
		mounted() {
			const currentInstance = this
			this.$('.policy-scroll').click(function (e) {
				const height = currentInstance.getHeight()
				$('#kvkk-policy, #user-policy').animate({ scrollTop: height }, 'slow')
				return false
			})

			if (this.$route.query.code) {
				this.Form.InvitationCode = this.$route.query.code
				this.hasInvitationCode = true
			}
		},
		props: {
			fromDialog: {
				type: Boolean,
				default: false,
			},
		},
		methods: {
			handleDialogOpen() {
				this.$('.v-modal')[0].remove()
			},
			getHeight() {
				console.log('this.kvkkDialog: ', this.kvkkDialog)
				return this.kvkkDialog ? this.$('#kvkk-policy-inside').height() : this.$('#user-policy-inside').height()
			},
			handleCheckbox(e) {
				//  window.sendNotification.warning("Onaylamak için okumanız gerekmektedir");
				// e.preventDefault();
				// e.stopPropagation();
			},
			updateTCKimlikNo(e) {
				this.Form.TCKimlikNo = e.replace(/[^\d]/g, '')
			},
			showPassword() {
				const passwordType = this.$('#password').attr('type')
				if (passwordType === 'text') {
					this.$('#togglePassword').removeClass('bi-eye')
					this.$('#togglePassword').addClass('bi-eye-slash')
					this.$('#password').attr('type', 'password')
				} else if (passwordType === 'password') {
					this.$('#togglePassword').removeClass('bi-eye-slash')
					this.$('#togglePassword').addClass('bi-eye')
					this.$('#password').attr('type', 'text')
				}
			},
			showPasswordRetry() {
				const passwordRetryType = this.$('#passwordRetry').attr('type')
				if (passwordRetryType === 'text') {
					this.$('#togglePasswordRetry').removeClass('bi-eye')
					this.$('#togglePasswordRetry').addClass('bi-eye-slash')
					this.$('#passwordRetry').attr('type', 'password')
				} else if (passwordRetryType === 'password') {
					this.$('#togglePasswordRetry').removeClass('bi-eye-slash')
					this.$('#togglePasswordRetry').addClass('bi-eye')
					this.$('#passwordRetry').attr('type', 'text')
				}
			},
			handleAcceptKvkk() {
				this.registerPolicies.kvkk = true
				this.kvkkDialog = false
			},
			handleAcceptLocation() {
				this.registerPolicies.location = true
				this.locationDialog = false
			},
			handleAcceptUserAgreement() {
				this.registerPolicies.userAgreement = true
				this.userAgreementDialog = false
			},
			handleAcceptConfidentialityAgreement() {
				this.registerPolicies.confidentialityAgreement = true
				this.confidentialityAgreementDialog = false
			},
			back() {
				this.isCarrier = null
				this.districts = null
				this.activeRegister--
				this.resetResponses()
			},
			goBackToApp() {
				if (this.$store.state.User.registerHomePageDialog == true) {
					this.$store.commit('setRegisterHomePageDialog', false)
				} else {
					this.$router.push({ path: '/' })
				}
			},
			getToken() {
				return localStorage.getItem('token')
			},
			async signUp() {
				var req = this.Form
				if (req.PhoneNumber != null && req.PhoneNumber != '') {
					req.PhoneNumber = this.$phoneNumberFormatString(req.PhoneNumber)
				}

				// add if user signing up from guest advert adding form
				req.IsSupplier = this.$store.getters.loginAsSupplier

				this.$store.dispatch('signUpUser', {
					isCarrier: this.isCarrier,
					Form: req,
				})
			},
			async addBillDetails() {
				var req = this.BillForm
				if (req.PhoneNumber != null && req.PhoneNumber != '') {
					req.PhoneNumber = this.$phoneNumberFormatString(req.PhoneNumber)
				}
				this.$store.dispatch('addBillDetails', req)
			},
			next() {
				this.districts = null
				if (this.activeRegister === 2) {
					if (this.registerPolicies.kvkk && this.registerPolicies.userAgreement) this.signUp()
					else window.sendNotification.error('Devam etmek için sözleşmeleri kabul edin!')
				} else if (this.activeRegister === 3) {
					this.addBillDetails()
				} else {
					this.activeRegister++
				}
			},
			handleAvatarSuccess(res) {
				if (res.HasError) {
					window.sendNotification.error(res.Message)
				} else {
					window.sendNotification.success(res.Message)
					this.BillForm.CompanyLogo = res.Data.Url
				}
			},
			navigateToHomePage() {
				this.$router.push({ path: '/anasayfa' })
			},
			resetResponses() {
				this.companyResponse = { ValidationErrors: [] }
				this.signUpUserResponse = { ValidationErrors: [] }
				this.Form = {
					FirstName: null,
					LastName: null,
					PhoneNumber: null,
					Email: null,
					Password: null,
					PasswordRetry: null,
					CityId: null,
					DistrictId: null,
					TCKimlikNo: null,
					invitationCode: null,
				}
				this.BillForm = {
					CompanyLogo: null,
					Title: null,
					Email: null,
					PhoneNumber: null,
					CityId: null,
					DistrictId: null,
					TaxNo: null,
					TaxOffice: null,
					Address: null,
				}

				if (this.$route.query.code) this.Form.InvitationCode = this.$route.query.code
			},
		},
		watch: {
			hasInvitationCode(val, old) {
				if (!val) this.Form.InvitationCode = ''
			},
			isCarrier(val) {
				if (val) {
					$('#carrier').addClass('select-active')
					$('#supplier').removeClass('select-active')
				} else {
					$('#carrier').removeClass('select-active')
					$('#supplier').addClass('select-active')
				}
			},
			// "Form.CityId": async function (val) {
			//   this.districts = await this.$getDistricts(val);
			//   this.Form.DistrictId = null;
			// },
			// "BillForm.CityId": async function (val) {
			//   this.districts = await this.$getDistricts(val);
			//   this.BillForm.DistrictId = null;
			// },
		},
		destroyed() {
			this.activeRegister = 1
			this.resetResponses()
		},
		computed: {
			locationInfo: {
				get() {
					const cookie = this.$store.getters['policies'].find((policy) => policy.Type === POLICIES.LOCATION_DATA)
					return cookie ? cookie : { Title: '', Content: '' }
				},
			},
			kvkkInfo: {
				get() {
					const cookie = this.$store.getters['policies'].find((policy) => policy.Type === POLICIES.KVKK)
					return cookie ? cookie : { Title: '', Content: '' }
				},
			},
			userAgreementInfo: {
				get() {
					const cookie = this.$store.getters['policies'].find((policy) => policy.Type === POLICIES.USER_AGREEMENT)
					return cookie ? cookie : { Title: '', Content: '' }
				},
			},
			confidentialityAgreementInfo: {
				get() {
					const cookie = this.$store.getters['policies'].find((policy) => policy.Type === POLICIES.CONFIDENTIALITY)
					return cookie ? cookie : { Title: '', Content: '' }
				},
			},
			activeRegister: {
				get() {
					return this.$store.getters['activeRegister']
				},
				set(newValue) {
					this.$store.commit('activeRegister', newValue)
				},
			},
			companyResponse: {
				get() {
					return this.$store.getters['companyResponse']
				},
				set(newValue) {
					this.$store.commit('companyResponse', newValue)
				},
			},
			signUpUserResponse: {
				get() {
					return this.$store.getters['signUpUserInfo']
				},
				set(newValue) {
					this.$store.commit('signUpUserInfo', newValue)
				},
			},
		},
	}
</script>

<style lang="scss" scoped>
	@import './style.scss';
</style>
