import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import Login from "@/components/login/Login";
import Landingpage from "@/components/landingpage/Landingpage";
import Register from "@/pages/register/Register";

// Component Imports

// -------------------

// LayoutsS

const Layout = () => import("../layouts/Layout");

// -------------------

// pages

const Dashboard = () => import('../pages/dashboard/Dashboard')
const Comments = () => import('../pages/comments/Comments')
const Ads = () => import('../pages/ads/Ads')
const Shipments = () => import('../pages/shipments/Shipments')
const Information = () => import('../pages/information/Information')
const ReviewForm = () => import('../pages/reviewForm/ReviewForm')
const Payments = () => import('../pages/payments/Payments')
const Shop = () => import('../pages/shop/Shop')
const Detail = () => import('../pages/details/Details')
const NewAd = () => import('../pages/newAd/NewAd')
const ShipmentDetail = () => import('../pages/shipmentDetail/shipmentDetail')
const PageNotFound = () => import('../pages/NotFound/PageNotFound')
const Dealer = () => import('../pages/dealer/Dealer') //dealer componenti route eklendi Yusuf Boz
//import home from "../pages/home/home"

// -------------------

// Components
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);
// eslint-disable-next-line no-unused-vars
const defaultChildRoutes = (prop, mode) => [
    {
        path: '/',
        name: prop + "dashboard",
        meta: { auth: true, name: 'Yönetim Paneli' },
        component: Dashboard
    },
    {
        path: '/degerlendirmelerim',
        name: prop,
        meta: { auth: true, name: 'Yorumlar ve Değerlendirmeler' },
        component: Comments
    },
    {
        path: '/profil',
        name: prop + '.profil',
        meta: { auth: true, name: 'Kullanıcı Bilgileri' },
        component: Information
    },
    {
      path: '/reviewForm',
      name: prop + '.reviewForm',
      meta: { auth: true, name: 'Görüş ve Öneriler' },
      component: ReviewForm
  },
    {
        path: '/dealer',
        name: prop,// + '.profil'
        meta: { auth: true, name: 'Bayi' },
        component: Dealer
    },//bayi route eklenmesi Yusuf Boz
    {
        path: '/odemelerim',
        name: prop,
        meta: { auth: true, name: 'Ödemelerim' },
        component: Payments
    },
    {
        path: '/magaza',
        name: 'prop',
        meta: { auth: true, name: 'Mağaza' },
        component: Shop
    }
]

const sevkiyatlarimChildRoutes = (prop, mode) => [
  {
    path: "/",
    name: "Sevkiyatlarım",
    meta: { auth: true, name: "Sevkiyatlarım" },
    component: Shipments,
  },
  {
    path: "/sevkiyatlarim/detay",
    name: prop + ".detay",
    meta: { auth: true, name: "Sevkiyat Detay" },
    component: ShipmentDetail,
  },
];

const ilanlarChildRoutes = (prop, mode) => [
  {
    path: "/ilanlar/aktif",
    name: prop + '.aktif',
    meta: { auth: false, name: "İlanlar" },
    component: Ads,
  },
  {
    path: "/ilanlar/detay",
    name: prop + ".detay",
    meta: { auth: false, name: "İlan Detay" },
    component: Detail,
    props: true,
  },
  {
    path: "olustur",
    name: prop,
    meta: { auth: true, name: "İlan Oluştur" },
    component: NewAd,
  },
];

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/",
    name: "Landingpage",
    component: Landingpage,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/anasayfa",
    name: "HomePage",
    component: Layout,
    children: defaultChildRoutes("home"),
  },
  {
    path: "/index.html",
    name: "Index",
    component: Landingpage,
    beforeEnter(to, from, next) {
      next({
        path: "/",
        replace: true,
      });
    },
  },
  {
    path: "/ilanlar",
    name: "İlanlarım",
    component: Layout,
    children: ilanlarChildRoutes("ilanlar"),
    props: true,
  },
  {
    path: "/sevkiyatlarim",
    name: "Sevkiyatlarım",
    component: Layout,
    children: sevkiyatlarimChildRoutes("sevkiyatlarim"),
    props: true,
  },
  {
    path: "/:catchAll(.*)",
    component: PageNotFound,
    name: "NotFound",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

/**
 * Route islemlerinde kullanicinin authentication durumunu kontrol
 * etmek icin kullanilacaktir(public sayfalara giris sadece authenticate
 *  olunmadigi durumlarda izin verildi).
 */
router.beforeEach(async (to, from, next) => {
  const publicPages = ["/login", "/", "/register", "/ilanlar/aktif", "/ilanlar/detay"];

  const authRequired = !publicPages.includes(to.path);

  let loggedIn = await store.getters["userState"].ID;
  let token = localStorage.getItem("token");
  // console.log(token);
  // console.log("router calıstı", authRequired, loggedIn);

  if (token) {
    if (!loggedIn) {
      console.log("router calıstı3", loggedIn);
      await store.dispatch("getUser");
      loggedIn = await store.getters["userState"].ID;
      console.log("router calıstı33", loggedIn);
    }
  }

  if (authRequired && !loggedIn) {
    // console.log("router calıstı4", authRequired, loggedIn);
    store.commit("toggleLoginDialog", true);
  }
  // } else if (!authRequired && loggedIn) {
  //   console.log(to.name)

  //   // console.log("router calıstı5", authRequired, loggedIn);
  //   if (to.path !== "/ilanlar/aktif" || to.path !== "/ilanlar/detay") {
  //     return next("/anasayfa");
  //   }
  // }

  console.log(to.name)


  next();
});
export default router;
